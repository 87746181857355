<template>
  <PrivacyComponent />
</template>

<script>
import PrivacyComponent from '../components/PrivacyComponent'

export default {
  components: {
    PrivacyComponent
  }
}
</script>
