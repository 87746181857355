<template>
  <div>
    <v-parallax height="400" src="@/assets/bspace.jpg">
      <particles
        id="first"
        color="#dedede"
        :particleOpacity="0.5"
        :particlesNumber="60"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dddddd"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="6"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="repulse"
        style="height:100%;width:100%"
      ></particles>
    </v-parallax>
    <v-container>
      <v-layout text-center wrap>
        <v-flex xs-12 mb-5>
          <h3><v-icon class="large">mdi-account-multiple</v-icon></h3>
          <h4>{{ policy.title }}</h4>
          <div v-html="policy.content"></div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-parallax height="400" src="@/assets/planets.jpg">
      <particles
        id="second"
        color="#dedede"
        :particleOpacity="0.5"
        :particlesNumber="60"
        shapeType="circle"
        :particleSize="5"
        linesColor="#dddddd"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="6"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="repulse"
        style="height:100%;width:100%"
      ></particles>
    </v-parallax>
  </div>
</template>

<script>
import particles from './particles'
export default {
  name: 'PrivacyComponent',
  components: { particles },
  data: () => ({
    policy: {
      title: 'Politique de confidentialité',
      content: `<p><strong>Introduction</strong><br />
Devant le développement des nouveaux outils de communication, il est nécessaire de porter une attention particulière à la protection de la vie privée.
C'est pourquoi, nous nous engageons à respecter la confidentialité des renseignements personnels que nous collectons.
</p><hr/>
<h2>Collecte des renseignements personnels</h2>
<p>
Nous collectons les renseignements suivants :
</p>
<ul>
  <li>Nom</li>
  <li>Prénom</li>
  <li>Adresse électronique</li>
  <li>Numéro de téléphone / télécopieur</li>
  <li>Préférences (musicales, littéraires, cinématographiques, etc.)</li>
</ul>
<p>
  Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à
  l'interactivité établie entre vous et notre site Web.
  Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux
  pour réunir des informations vous concernant.
</p><hr/>
<h2>Formulaires et interactivité:</h2>
<p>
  Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :
</p>
<p>
  Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :
</p>
<ul>
  <li>Contact</li>
</ul>
<p>
  Vos renseignements sont également collectés par le biais de l'interactivité pouvant s'établir entre vous et notre site Web et ce, de la façon suivante:
</p>
<ul>
  <li>Statistiques</li>
  <li>Contact</li>
</ul>
<p>
  Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :<br />
</p>
<hr/>
<h2>Droit d'opposition et de retrait</h2>
<p>
  Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels.<br />
  Le droit d'opposition s'entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements
  personnels soient utilisées à certaines fins mentionnées lors de la collecte.<br />
</p>
<p>
  Le droit de retrait s'entend comme étant la possiblité offerte aux internautes de demander à ce que leurs
  renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.<br />
</p>
<p>
  Pour pouvoir exercer ces droits, vous pouvez : <br />
    Courriel : cedric.meyer@viacesi.fr<br />  Téléphone : 0677226414<br />  </p><hr/>
<h2>Sécurité</h2>
<p>
  Les renseignements personnels que nous collectons sont conservés
  dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.<br />
  Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :
</p>
<ul>
  <li>Gestion des accès - personne autorisée</li>
  <li>Sauvegarde informatique</li>
  <li>Développement de certificat numérique</li>
  <li>Identifiant / mot de passe</li>
  <li>Pare-feu (Firewalls)</li>
</ul>

<p>
  Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d'assurer
  la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n'offre une sécurité maximale, une part de risque est toujours présente
  lorsque l'on utilise Internet pour transmettre des renseignements personnels.
</p>`
    }
  })
}
</script>

<style lang="scss">
.v-list.skill-list {
  background: transparent !important;

  .v-subheader {
    justify-content: center;
  }
}
</style>
