<template>
  <HomeComponent/>
</template>

<script>
import HomeComponent from '../components/HomeComponent'

export default {
  components: {
    HomeComponent
  }
}
</script>
